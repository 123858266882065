<template>
  <section>
    <el-form :inline="true"
             style="overflow: auto;white-space: nowrap;background: #FFFFFF;padding-left: 25px;padding-top: 15px;"
             size="mini">

      <el-form-item>
        <el-button size="mini"
                   type="success"
                   icon="el-icon-brush"
                   @click="batchSetReadComplated">全部已读</el-button>
      </el-form-item>
      <el-form-item label="任务类型:"
                    style="width:180px">
        <el-select v-model="searchTaskClassify"
                   style="width:60%;">
          <el-option v-for="item in ClassifyModules"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="工作项目:">
        <el-input v-model="workname"
                  placeholder="请输入"
                  maxlength="10"
                  show-word-limit
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="查阅状态:"
                    style="width:150px">
        <el-select v-model="taskStatus"
                   style="width:50%;">
          <el-option v-for="item in StatusModules"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button size="mini"
                   type="goon"
                   icon="el-icon-search"
                   @click="getWaitReadTasks">查询</el-button>
      </el-form-item>
    </el-form>
    <!--工具条-->
    <!-- <toolbar :buttonList="buttonList" @callFunction="callFunction" :buttonListmsg="buttonListmsg"></toolbar> -->
    <!--列表-->
    <el-table :data="users"
              highlight-current-row
              row-key="Id"
              ref="tabTask"
              lazy
              :indent="30"
              :load="load"
              :tree-props="{children: 'children',hasChildren: 'IsHasChildren'}"
              :fit="true"
              @current-change="selectCurrentRow"
              :row-style="{height:'60px'}"
              :cell-style="{padding:'0px'}"
              :header-cell-style="{background:'#dce4f1',color:'#000000'}"
              @row-dblclick="checkInfo"
              @cell-mouse-enter="tabmouseEnter"
              @cell-mouse-leave="tabmouseLeave"
              style="width: 100%">
      <!-- <el-table-column type="index"></el-table-column> -->
      <el-table-column type="index" width="10">
        <template scope="scope">
        </template>
      </el-table-column>
      <!-- <el-table-column type="selection"></el-table-column> -->
      <el-table-column label="工作项目"
                       prop="WorkItem"
                       min-width="450px"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <!-- <i class="el-icon-view"
             v-if="scope.row.IsHasChildren == true"></i> -->
          <!-- <i class="el-icon-view" v-if="scope.row.ParentId != null && scope.row.IsHasChildren == false"></i> -->
            <el-button @click="handleCollect(scope.row)" style="color:#FF9800;margin-right:3px;" size="mini" v-show=" scope.row.PersonOfDuty!='' && (collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null" :title="scope.row.CollectId==null?'特别关注':'已特别关注'" type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button><span :style="{'cursor':'default'}">{{scope.row.Number}}、{{scope.row.WorkItem}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="具体描述及要求" prop="WorkDescription"></el-table-column> -->
      <el-table-column label="计划完成时间"
                       prop="PlanComplateTime"
                       min-width="110px"
                       :formatter="formatPlanComplateTime">
        <template slot-scope="scope">
          <div v-if="new Date()>new Date(new Date(new Date(scope.row.PlanComplateTime).toLocaleDateString()).getTime()+24*60*60*1000-1)&&scope.row.TaskProgressValue<100">
            <font color=red>{{formatPlanComplateTime(scope.row)}}</font>
          </div>
          <div v-else>
            <font color=black>{{formatPlanComplateTime(scope.row)}}</font>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="责任人"
                       prop="PersonOfDuty"
                       min-width="70px"></el-table-column>
      <el-table-column label="任务状态"
                       prop="Status"
                       align="center"
                       min-width="100px">
        <template slot-scope="scope">
            <div>
              <div v-if="scope.row.TaskProgressValue<100"  style="font-size:10px;">进度:{{formatProgress(scope.row)}}</div>
              <el-tag v-if="scope.row.Status == 0"
                      effect="dark"> 进行中</el-tag>
              <el-tag v-if="scope.row.Status == 2"
                      effect="dark"
                      type="success">已完成</el-tag>
              <el-tag v-if="scope.row.Status == -1"
                      effect="dark"
                      type="info">已取消</el-tag>
              <el-tag v-if="scope.row.Status == 3"
                      effect="dark"
                      type="danger">退回</el-tag>
              <el-tag v-if="scope.row.Status == 1"
                      effect="dark"
                      type="warning">已完成审核中</el-tag>
              <el-tag v-if="scope.row.Status == 4"
                      effect="dark"
                      type="warning">新建任务待审核</el-tag>
              <el-tag v-if="scope.row.Status == 5"
                      effect="dark"
                      type="danger">取消任务待审核</el-tag>
              <el-tag v-if="scope.row.Status == 6"
                      effect="dark"
                      type="danger">新建任务退回</el-tag>
            </div>
        </template>
      </el-table-column>

      <el-table-column label="任务类型"
                       prop="CommonTaskClassifyText"
                       min-width="100px"
                       :formatter="formatCommonTaskClassifyText"></el-table-column>
      <!-- <el-table-column label="任务来源"
                       prop="Source"
                       :formatter="formatSource"
                       min-width="100px"></el-table-column> -->
      <el-table-column label="任务来源"
              prop="Source"
              :formatter="formatSource"
              min-width="90px">
        <template slot-scope="scope">
          <div style="font-size:10px">{{formatSource(scope.row)}}</div>
          <div v-if="scope.row.AssignName" style="font-size:10px">由{{scope.row.AssignName}}指派</div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间"
                       prop="CreateTime"
                       min-width="90px"
                       :formatter="formatCreateTime">
        <template slot-scope="scope">
          <el-tooltip placement="left">
            <div slot="content">
              {{ formatTipCreateTime( scope.row) }}
            </div>
            <div>
              {{ formatCreateTime( scope.row) }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="查阅情况"
                       prop="ReadComplated"
                       min-width="70px">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.ReadComplated == 0"
                  type="danger"> 未读 </el-tag>
          <el-tag v-else-if="scope.row.ReadComplated == 1"
                  type="success">已读</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column label="完成后需审核" prop="IsNeedApproval" align="center">
                <template slot-scope = "scope">
                <el-tag :type = "scope.row.IsNeedApproval ? 'danger' : 'success'"  disable-transitions>
                    {{scope.row.IsNeedApproval ? "是" : "否"}}
                </el-tag>
                </template>
            </el-table-column> -->
      <!-- <el-table-column label="备注"  width="100"  prop="Remark"></el-table-column> -->
      <el-table-column label="操作"
                       min-width="120px">
        <template slot-scope="scope">
          <el-tooltip placement="left" effect="light"
                      v-show="scope.row.PersonOfDuty!=''"
                      hidden
                      content="双击也可以查看任务进度喔~">
            <el-button type="text"
                       size="medium"
                       @click="checkInfo(scope.row)">查看</el-button>
          </el-tooltip>
          <el-button v-show="scope.row.PersonOfDuty!='' && scope.row.ReadComplated==0 "
                     type="text"
                     size="medium"
                     @click="setReadComplated(scope.row)">标记已读</el-button>
          <el-button @click="setNoTaskReadComplated(scope.row)"
                     v-show="scope.row.NoTask>0 "
                     type="text"
                     size="medium">标记已读</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination align='center'
                   @current-change="handleCurrentChange"
                   :current-page="currentPage"
                   :page-size="pageSize"
                   layout="prev, pager, next, jumper"
                   :page-count="total"
                   :total="totaldata">
    </el-pagination>

    <taskinfo v-if="checkVisible" :data="checkForm" @closeFunction="checkclose" type="normal" ></taskinfo>

  </section>
</template>
<script>
import util from '../../../util/date';
import Toolbar from "../../components/Toolbar";
import { AddTaskCollect, RemoveTaskCollect, AddTaskProgressReview,QueryReviewByProgressId, SetAllWaitReadTaskReadComplated, SetReadComplatedByWhere, QueryPageWaitReadTasksByUserCode201228, QueryChildTasksByParentId, QueryTasksById, QueryTaskProgressByTaskId, TaskReadToComplateById } from "../../api/oa";
import taskinfo from "../../components/taskinfo.vue"
import { getButtonList } from "../../promissionRouter";
export default {
  components: { taskinfo, Toolbar },
  data () {
    return {
      currentActiveId: null,
      maps: new Map(),
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 10, // 每页的数据条数
      page: 1,
      buttonList: [],
      users: [],
      currentRow: null,
      filters: {
        name: ''
      },
      workname: '',
      taskStatus: 0,
      buttonListmsg: 'taskmanage',
      checkVisible: false,
      checkForm: {            //查看表单初始化
        Id: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsNeedApproval: null
      },
      StatusModules: [
        {
          value: -1,
          label: "全部"
        },
        {
          value: 0,
          label: "待阅"
        },
        // {
        //     value: 1,
        //     label: "已完成审核中"
        // },
        {
          value: 1,
          label: "已阅"
        },
        // {
        //     value: 3,
        //     label: "退回"
        // },
        // {
        //     value: 4,
        //     label: "新建任务待审核"
        // },
        // {
        //     value: 5,
        //     label: "取消任务待审核"
        // },
        // {
        //     value: 6,
        //     label: "新建任务退回" 
        // },

      ],
      searchTaskClassify: 0,
      ClassifyModules: [
        {
          value: 0,
          label: "全部"
        },
        {
          value: 1,
          label: "日常工作"
        },
        {
          value: 3,
          label: "例会项目"
        },
        {
          value: 5,
          label: "专项会议"
        },
      ],
      showAssign: false,
      createPerson: false,                    //创建人是否显示
      showyearplan: false,                    //年度计划查看界面显示控制
      showorganize: false,                     //跨部门协同查看界面显示控制
      showregularproject: false,               //例会来源查看界面控制
      showprofessionmeetong: false,            //专项会议查看界面控制
      CommonTaskClassifyText: null,
      pValue1: null,   //进度条数值
      istaskpro: false,
      tasks: [],
      collectBtn:false,
      collectRowId:'',
      content:'',
      mapReview:new Map()
    }
  },
  methods: {
    handleCollect(row)
    {
      if(row.CollectId==null)
      {
        var para={};
        para.TaskID=row.Id;
        var user = JSON.parse(window.localStorage.user);
        para.CreatedId = user.sub;
        para.CreatedBy = user.name;
        // console.log(para)
        // return;
        AddTaskCollect(para).then((res) => {
          this.$message({
            message: '已特别关注',
            type: 'success'
          });
          // console.log(res);
          var id=res.data.response;
          row.CollectId=id;
        })
      }else{
        this.$confirm("确定要关闭对此任务的关注吗？", "提示", {}).then(() => {
          var para={};
          para.Id=row.CollectId;
          para.TaskID=row.Id;
          RemoveTaskCollect(para).then((res) => {
            this.$message({
              message: '已取消关注',
              type: 'success'
            });
            row.CollectId=null;
          })
        }).catch(()=>{});
      }
    },
    tabmouseEnter(row, column, cell, event)
    {
      this.collectBtn=true;
      this.collectRowId=row.Id;
      // console.log(row)
    },
    tabmouseLeave(row, column, cell, event)
    {
      this.collectBtn=false;
      this.collectRowId='';
    },
    handleChange(val)
    {
      // console.log(val)
      if(val.length>0)
      {
        var progressId = val[0];
        this.loadReview(progressId);
      }
    },
    catchData(c)
    {
      // console.log(c)
      this.content=c;
    },
    handlePopoShow()
    {
      
    },
    handlePopoReplyShow()
    {

    },
    myname()
    {
      var user = JSON.parse(window.localStorage.user);
      return user.name;
    },
    handleReplyReview(progress,rid)
    {
      // console.log(progress);
      // console.log(rid);
      let self=this;
      var progressId = progress.Id;
      var user = JSON.parse(window.localStorage.user);
      var para={};
      para.TaskID=this.checkForm.Id;
      para.ProgressID=progressId;
      para.ReplayID = rid;
      para.ReviewContent = self.content;
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      // console.log(para);

      AddTaskProgressReview(para).then((res) => {
        // console.log(res);
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          for(var a=0;a<self.tasks.length;a++)
          {
            if(this.tasks[a].Id==progressId)
            {
              this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
              break;
            }
          }
          this.loadReview(progressId);
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      })
    },
    handleSendReview(progress)
    {
      let self=this;
      // this.$confirm('评论即将发表，确定吗？', '提示', {}).then(() => 
      {
        var progressId = progress.Id;
        var user = JSON.parse(window.localStorage.user);
        var para={};
        para.TaskID=this.checkForm.Id;
        para.ProgressID=progressId;
        para.ReplayID = null;
        para.ReviewContent = self.content;
        para.CreatedId = user.sub;
        para.CreatedBy = user.name;
        // console.log(para);

        AddTaskProgressReview(para).then((res) => {
          // console.log(res);
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            for(var a=0;a<self.tasks.length;a++)
            {
              if(this.tasks[a].Id==progressId)
              {
                this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
                break;
              }
            }
            this.loadReview(progressId);
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        })
      }
      // )
    },
    loadReview(progressid)
    {
      let self=this;
      // console.log(progressid)
      let para = { progressId: progressid };
      QueryReviewByProgressId(para).then((res) => {
        // console.log(res)
        var data = res.data.response;
        // console.log(data);
        self.mapReview.set(progressid,data);
        // console.log(self.mapReview);
        this.$forceUpdate()
        // for(var a=0;a<data.length;a++)
        // {
        //   self.mapReview.set(progressid,data);
        // }
      });
    },
    getReplyReview(arr,id)
    {
      // console.log(arr);
      // console.log(id);
      var o ={
        CreatedBy:'',
        CreatedId:'',
        CreatedTime:'',
        ReviewContent:''
      };
      for(var i=0;i<arr.length;i++)
      {
        if(arr[i].Id==id)
        {
          o = arr[i];
          break;
        }
      }
      return o;
    },
    handleProcessSelectChange (taskid, index) {
      this.currentActiveId = taskid;
      this.currentProgressRowIndex = index;
    },
    showTaskFile (file) {
      window.open(file, "_blank");
    },
    setNoTaskReadComplated (row) {
      var user = JSON.parse(window.localStorage.user);
      var userCode = user.sub;
      var notaskType = row.NoTask;
      let para = {
        taskId: row.TaskId,
        readusercode: userCode,
        notaskType: notaskType,
      };
      SetReadComplatedByWhere(para).then(res => {
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });

          this.upchildrenDom(row.WorkItem);
          //this.getWaitReadTasks();
        } else {
          // this.$message({
          //     message: res.data.msg,
          //     type: 'error'
          // });
        }
      })
    },
    formatProgress: function (row, col) {
      return row.TaskProgressValue + "%";
    },
    batchSetReadComplated () {
      var user = JSON.parse(window.localStorage.user);
      var userCode = user.sub;
      let para = { usercode: userCode };
      SetAllWaitReadTaskReadComplated(para).then(res => {
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          setTimeout(() => {
            this.getWaitReadTasks();
          }, 200);
        } else {
          // this.$message({
          //     message: res.data.msg,
          //     type: 'error'
          // });
        }
      })
    },
    callFunction (item) {
      this.filters = {
        name: item.search
      };
      this[item.Func].apply(this, item);
    },
    selectCurrentRow (val) {
      this.currentRow = val;
    },
    formatPlanComplateTime: function (row, colume) {
      return (!row.PlanComplateTime || row.PlanComplateTime == '') ? '' : util.formatDate.format(new Date(row.PlanComplateTime), 'yyyy-MM-dd');
    },
    formatCreatedOn: function (row, colume) {
      return (!row.CreatedOn || row.CreatedOn == '') ? '' : util.formatDate.format(new Date(row.CreatedOn), 'yyyy-MM-dd hh:mm');
    },
    formatCommonTaskClassifyText: function (row, colume) {
      if (row.TaskClassify == 1) {
        // return row.CommonTaskClassifyText;
        return "日常工作";
      } else {
        if (row.TaskClassify == 2) {
          return "跨部门协调";
        }
        else if (row.TaskClassify == 3) {
          return "例会项目";
        } else if (row.TaskClassify == 4) {
          return "年度计划";
        } else if (row.TaskClassify == 5) {
          return "专项会议";
        }
        else {
          // return "其他";
          return "";
        }
      }
    },
    formatSource: function (row, column) {
      return row.ParentId != null ? '被指派' : row.ParentId == null ? '新建' : '未知';
    },
    formatCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.dateformat(new Date(row.CreateTime));
    },
    formatTipCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.format(new Date(row.CreateTime), 'yyyy-MM-dd hh:mm');
    },
    load (tree, treeNode, resolve) {
      // console.log(tree);
      const pid = tree.WorkItem;
      this.maps.set(pid, { tree, treeNode, resolve });
      // console.log(this.maps)
      var notaskType = tree.NoTask;
      if (notaskType == 0)//普通日常任务
      {
        var user = JSON.parse(window.localStorage.user);
        var userCode = user.sub;
        var readcomplated = this.taskStatus;
        let para = {
          taskId: tree.TaskId,
          readusercode: userCode,
          readcomplated: readcomplated
        };
        QueryChildTasksByParentId(para).then((res) => {
          var myarr = new Array();
          var number = 0;
          res.data.response.forEach(element => {
              element.Number = ++number;
              // element = this.rowformat(element);
              myarr.push(element)
          })
          resolve(myarr);
          // resolve(res.data.response)
        });
      }
      else {
        var user = JSON.parse(window.localStorage.user);
        var userCode = user.sub;
        var readcomplated = this.taskStatus;
        let para = {
          taskId: tree.TaskId,
          notaskType: notaskType,
          readusercode: userCode,
          readcomplated: readcomplated
        };
        QueryChildTasksByParentId(para).then((res) => {
          var myarr = new Array();
          var number = 0;
          res.data.response.forEach(element => {
              element.Number = ++number;
              // element = this.rowformat(element);
              myarr.push(element)
          })
          resolve(myarr);
          // resolve(res.data.response)
        });
      }
    },
    upchildrenDom (parentId) {
      // console.log('parentId:' + parentId)
      const { tree, treeNode, resolve } = this.maps.get(parentId); //根据pid取出对应的节点数据
      this.$set(this.$refs.tabTask.store.states.lazyTreeNodeMap, parentId, []); //将对应节点下的数据清空，从而实现数据的重新加载
      this.load(tree, treeNode, resolve)
    },
    //翻页处理
    handleCurrentChange (val) {
      this.page = val;
      this.getWaitReadTasks();
      // this.page = 1;
    },
    getWaitReadTasks () {
      var user = JSON.parse(window.localStorage.user);
      let para = {
        userCode: user.sub,
        pageIndex: this.page,
        pageSize: this.pageSize,
        taskClassify: this.searchTaskClassify,
        readState: this.taskStatus,
        queryKey: this.workname
      };
      // console.log(para)
      QueryPageWaitReadTasksByUserCode201228(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        var arr=new Array();
        console.log(this.page)
        console.log(this.pageSize);
        var number = (this.page-1)*this.pageSize;
        console.log(number);
        res.data.response.data.forEach(element => {
          // console.log(element);
          element.Number=++number;
          element.MarginLeft=3;
          element.Level = 1;
          // this.mapLevel.set(element.Id,1);
          arr.push(element)
        });
        this.users = arr;
        // this.users = res.data.response.data;
      });
    },
    setReadComplated (row) {
      if (row.ReadComplated == 0) {
        //   let para = {id: row.Id}
        let para = { id: row.WaitReadId }
        TaskReadToComplateById(para).then(res => {
          if (res.data.success) {

            // console.log(row.NoTask);
            if (row.TaskClassify == 3)//例会
            {
              var meetingDate = row.MeetingTime.split('T')[0];
              var dateArr = meetingDate.split('-');
              var month = dateArr[0] + '年' + Number(dateArr[1]) + '月' + dateArr[2] + '日';
              let parentId = month + row.MeetingName;
              this.upchildrenDom(parentId);
            } else if (row.TaskClassify == 5)//专项
            {
              let parentId = row.MeetingName;
              this.upchildrenDom(parentId);
            }
            // var meetingDate=row.MeetingTime.split('T')[0];
            // var dateArr = meetingDate.split('-');
            // var month=dateArr[0]+'年'+Number(dateArr[1])+'月'+dateArr[2]+'日';
            // let parentId= month+row.MeetingName;
            // this.upchildrenDom(parentId);
            console.info("已读");
          } else {
            // this.$message({
            //     message: res.data.msg,
            //     type: 'error'
            // });
            console.info("读取失败");
          }
        })
      }
    },
    //双击查看详细信息
    checkInfo (row) {
      if (row.NoTask > 0) {
        return;
      }
      this.checkVisible = true;
      this.checkForm = row;
      return;
      var user = JSON.parse(window.localStorage.user);
      if (row.TaskClassify == 1) {
        // this.CommonTaskClassifyText = row.CommonTaskClassifyText;
        this.CommonTaskClassifyText = "日常工作";
        this.showorganize = false;
        this.showyearplan = false;
        this.showregularproject = false;
        this.showprofessionmeetong = false;
        if (row.ParentId != null) {
          this.showAssign = true;
        } else {
          this.showAssign = false;
        }
        this.createPerson = true;
      } else {
        if (row.TaskClassify == 2) {
          this.CommonTaskClassifyText = "跨部门协调";
          this.showorganize = true;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          if (row.ParentId != null) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = false;
        }
        else if (row.TaskClassify == 3) {
          this.CommonTaskClassifyText = "例会项目";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = true;
          this.showprofessionmeetong = false;
          if (row.ParentId != null) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = true;
        } else if (row.TaskClassify == 4) {
          this.CommonTaskClassifyText = "年度计划";
          this.showorganize = false;
          this.showyearplan = true;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          if (row.ParentId != null) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = false;
        } else if (row.TaskClassify == 5) {
          this.CommonTaskClassifyText = "专项会议";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = true;
          if (row.ParentId != null) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = true;
        }
        else {
          this.CommonTaskClassifyText = "其他";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          if (row.ParentId != null) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = false;
        }
      }
      QueryTasksById({ Id: row.TaskId, taskClassify: row.TaskClassify }).then((res) => {
        this.checkForm = res.data.response;
        this.checkForm.Files = [];
        if (res.data.response.FileName != null) {
          var arr = res.data.response.FileName.split(",");
          var arr2 = res.data.response.FileAddress.split(",");
          var files = [];
          for (var a = 0; a < arr.length; a++) {
            var f = {};
            f.FileName = arr[a];
            f.FileAddress = arr2[a];
            files.push(f);
          }
          this.checkForm.Files = files;
        }
      })
      this.pValue1 = row.TaskProgressValue;
      //   this.checkForm = Object.assign({},row);
      let para = { taskId: row.TaskId };
      QueryTaskProgressByTaskId(para).then((res) => {
        //   this.tasks = res.data.response;
        var data = res.data.response;
        let formatData = new Array();
        for (var a = 0; a < data.length; a++) {
          var element = data[a];
          element.Files = [];
          if (element.FileName != null) {
            var arr = element.FileName.split(",");
            var arr2 = element.FileAddress.split(",");
            var files = [];
            for (var d = 0; d < arr.length; d++) {
              var f = {};
              f.FileName = arr[d];
              f.FileAddress = arr2[d];
              files.push(f);
            }
            element.Files = files;
          }
          formatData.push(element);
        }
        this.tasks = formatData;
        this.$nextTick(function () {
          var div = document.getElementsByClassName("tasksdiv");
          if (div && 0 < div.length) {
            div[0].scrollTop = div[0].scrollHeight;
          }
        })
      });
      // console.log(row);
      if (row.ReadComplated == 0) {
        //   let para = {id: row.Id}
        let para = { id: row.WaitReadId }
        TaskReadToComplateById(para).then(res => {
          if (res.data.success) {
            // this.$message({
            //     message: res.data.msg,
            //     type: 'success'
            // });
            // let parentId= row.ParentId;

            if (row.TaskClassify == 3)//例会
            {
              var meetingDate = row.MeetingTime.split('T')[0];
              var dateArr = meetingDate.split('-');
              var month = dateArr[0] + '年' + Number(dateArr[1]) + '月' + dateArr[2] + '日';
              let parentId = month + row.MeetingName;
              this.upchildrenDom(parentId);
            } else if (row.TaskClassify == 5)//专项
            {
              let parentId = row.MeetingName;
              this.upchildrenDom(parentId);
            }
            // var meetingDate=row.MeetingTime.split('T')[0];
            // var dateArr = meetingDate.split('-');
            // var month=dateArr[0]+'年'+Number(dateArr[1])+'月'+dateArr[2]+'日';
            // let parentId= month+row.MeetingName;
            // this.upchildrenDom(parentId);
            console.info("已读");
          } else {
            // this.$message({
            //     message: res.data.msg,
            //     type: 'error'
            // });
            console.info("读取失败");
          }
        })
      }
    },
    checkclose () {
      this.checkVisible = false;
      this.getWaitReadTasks();
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      }
      return '';
    },
  },
  mounted () {
    this.getWaitReadTasks();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    //第一种写法，每个页面都需要写方法，但是可以做特性化处理
    // this.getButtonList(routers);

    //第二种写法，封装到 permissionRouter.js 中
    let buttons = window.localStorage.buttList ? JSON.parse(window.localStorage.buttList) : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  }
}
</script>
<style scoped>
.waitreaddiv,
.waitreadcard {
  height: calc(100vh - 220px);
}
.tasksdiv {
  max-height: calc(100vh - 350px);
  overflow: auto;
  padding-left: 2px;
}
.active {
  background-color: #00000014;
}
.eldropdownitem,
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
</style>